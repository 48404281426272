import React from 'react';
import { Container } from 'reactstrap';
import { Header, Footer, ScrollToTop } from './components';
import Routes from './Routes';

export default function App() {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Container fluid className="px-0" style={{ minHeight: '100vh' }}>
        <Routes />
      </Container>
      <Footer />
    </>
  );
}
