import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { PoolsideArtwork } from '../../assets/artwork';

export default function Poolside({ borderBottom = true }) {
  return (
    <ContentPane
      borderBottom={borderBottom}
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="poolside"
    >
      <Container>
        <Release
          artwork={PoolsideArtwork}
          title="Poolside"
          spotifyUrl="https://open.spotify.com/track/1yolv2dz4tNCEC7XZ7GNai"
          youTubeUrl="https://youtu.be/jSAVaWjb8p4"
          isMusicVideo
          appleMusicUrl="https://music.apple.com/us/album/poolside-single/1420457510"
          tidalUrl="https://tidal.com/browse/track/92911530?play=true"
        />
      </Container>
    </ContentPane>
  );
}
