import React from 'react';
import { Container } from 'reactstrap';

export default function Footer() {
  return (
    <>
      <Container
        fluid
        className="d-flex flex-row align-items-center justify-content-center bg-carbon text-pebble fs-xxs fw-regular p-3 border-top border-width-2 border-color-charcoal"
      >
        <i>&copy; 2015-{new Date().getFullYear()} Attalia Records, LLC</i>
      </Container>
      <div className="d-flex d-md-none" style={{ height: '53px' }} />
    </>
  );
}
