import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { DarkPlacesArtwork } from '../../assets/artwork';

export default function DarkPlaces() {
  return (
    <ContentPane
      borderBottom
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="dark-places"
    >
      <Container className="py-4">
        <Release
          artwork={DarkPlacesArtwork}
          title="Dark Places"
          spotifyUrl="https://open.spotify.com/track/6o4aSB94tU7GLpxa6xewdG?si=3f514f2cb9874624"
          appleMusicUrl="https://music.apple.com/us/album/dark-places/1583542704?i=1583542705"
          tidalUrl="https://tidal.com/browse/album/195823668"
          soundCloudUrl="https://soundcloud.com/younglukemusic/dark-places"
          iTunesUrl="https://music.apple.com/us/album/dark-places/1583542704?i=1583542705"
          youTubeUrl="https://youtu.be/1YO7KQ8dXsk"
        />
      </Container>
    </ContentPane>
  );
}
