import React from 'react';

const PageHeader = ({ title, uppercase, underline, className }) => {
  let titleClasses = uppercase ? 'text-uppercase' : '';
  let headerClasses = className ? className : '';

  return (
    <div className={`mt-5 ${titleClasses} ${headerClasses}`}>
      <h5 className="text-cloud font-weight-regular mb-0">{title}</h5>
      {underline ? <hr className="hr hr-cloud my-0" /> : null}
    </div>
  );
};

export default PageHeader;
