import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, NavbarBrand } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classnames from 'classnames/bind';
import styles from './Header.module.scss';

import ylLogoSymbolWhite from '../assets/images/logos/yl/symbol-white.svg';
import { SocialLinks } from '.';

const cx = classnames.bind(styles);

export default function Header() {
  const [showBackground, setShowBackground] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const delta = 5;
      const navbarHeight = document.getElementById('navbar').offsetHeight;
      const pixelsScrolled = window.scrollY;

      if (Math.abs(lastScrollTop - pixelsScrolled) <= delta) return;

      if (pixelsScrolled > lastScrollTop && pixelsScrolled > navbarHeight) {
        setShowBackground(true);
      } else if (pixelsScrolled <= navbarHeight) {
        setShowBackground(false);
      }

      setLastScrollTop(pixelsScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  const link = cx(
    styles.link,
    'p-md-3 py-2 px-4 pb-2 align-self-center d-none d-md-flex ls-1'
  );

  const bottomNav = cx(
    'bg-carbon border-top border-color-charcoal border-width-2 p-0 d-flex d-md-none justify-content-center'
  );

  const bottomNavLink = cx(
    styles.bottomNavLink,
    'd-flex flex-fill align-items-center flex-column p-2 border-width-2 text-pebble'
  );

  const bottomNavText = cx(styles.bottomNavText, 'fs-xxxs ls-1 text-uppercase');

  const navbar = cx(
    styles.navbar,
    showBackground &&
      'bg-carbon border-bottom border-width-2 border-color-charcoal',
    'p-4 d-flex justify-content-center justify-content-md-between'
  );

  return (
    <>
      <Navbar id="navbar" fixed="top" className={navbar}>
        <NavbarBrand href="/" className="py-0">
          <img src={ylLogoSymbolWhite} alt="Young Luke" width="45px" />
        </NavbarBrand>
        <NavLink
          to="/listen"
          activeClassName={styles.activeLink}
          className={link}
        >
          Listen
        </NavLink>
        {/* <NavLink
            to="/video"
            activeClassName={styles.activeLink}
            className={link}
          >
            Video
          </NavLink> */}
        <NavLink
          to="/photos"
          activeClassName={styles.activeLink}
          className={link}
        >
          Photos
        </NavLink>
        <NavLink
          to="/shop"
          activeClassName={styles.activeLink}
          className={link}
        >
          Shop
        </NavLink>
        <SocialLinks className="ml-auto" />
      </Navbar>
      <Navbar id="mobileNavbar" fixed="bottom" className={bottomNav}>
        <NavLink
          exact
          to="/"
          activeClassName="bg-charcoal text-white"
          className={bottomNavLink}
        >
          <FontAwesomeIcon icon="home" className="mb-1" />
          <span className={bottomNavText}>Home</span>
        </NavLink>
        <NavLink
          to="/listen"
          activeClassName="bg-charcoal text-white"
          className={bottomNavLink}
        >
          <FontAwesomeIcon icon="headphones-alt" className="mb-1" />
          <span className={bottomNavText}>Listen</span>
        </NavLink>
        {/* <NavLink
            to="/video"
            activeClassName="bg-charcoal text-white"
            className={bottomNavLink}
          >
            <FontAwesomeIcon icon="video" className="mb-1" />
            <span className={bottomNavText}>Video</span>
          </NavLink> */}
        <NavLink
          to="/photos"
          activeClassName="bg-charcoal text-white"
          className={bottomNavLink}
        >
          <FontAwesomeIcon icon="images" className="mb-1" />
          <span className={bottomNavText}>Photos</span>
        </NavLink>
        <NavLink
          to="/shop"
          activeClassName="bg-charcoal text-white"
          className={bottomNavLink}
        >
          <FontAwesomeIcon icon="shopping-cart" className="mb-1" />
          <span className={bottomNavText}>Shop</span>
        </NavLink>
      </Navbar>
    </>
  );
}
