import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.white75};
  &:hover {
    color: ${(props) => props.theme.white};
  }
  &:active {
    color: ${(props) => props.theme.white75};
  }
`;

const SocialLinks = ({ className }) => (
  <div className={className}>
    <SocialLink
      href="https://open.spotify.com/artist/33WLsuOJ1Hi5CnsY4a6Wv4"
      className="mr-3"
      icon={['fab', 'spotify']}
    />
    <SocialLink
      href="https://soundcloud.com/younglukemusic/"
      className="mr-3"
      icon={['fab', 'soundcloud']}
    />
    <SocialLink
      href="https://www.youtube.com/channel/UCgd0r_OPW583HPGfK0BP4Aw"
      className="mr-3"
      icon={['fab', 'youtube']}
    />
    <SocialLink
      href="https://itunes.apple.com/us/artist/young-luke/985557790"
      className="mr-3"
      icon={['fab', 'apple']}
    />
    <SocialLink
      href="https://www.instagram.com/younglukeofficial/"
      className="mr-3"
      icon={['fab', 'instagram']}
    />
    <SocialLink
      href="https://youngluke.bandcamp.com"
      icon={['fab', 'bandcamp']}
    />
  </div>
);

export default SocialLinks;

const SocialLink = ({ href, className, icon }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={className}
  >
    <StyledIcon icon={icon} size="lg" />
  </a>
);
