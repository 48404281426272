import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { litdFrontCover } from '../../assets/artwork';
import litdText from '../../assets/images/litd/text.svg';

export default function LightInTheDark() {
  return (
    <ContentPane
      borderBottom
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="litd"
    >
      <Container className="py-5 py-md-0">
        <div className="text-center">
          <img
            className="litd-album-name"
            src={litdText}
            alt="Light in the Dark"
          />
        </div>

        <Release
          artwork={litdFrontCover}
          title="Light in the Dark"
          showTitle={false}
          spotifyUrl="https://open.spotify.com/album/7oUh5cYxYXPEUXR0MiKJoi?si=L0BoCqhST92mx_yy0aLJDA"
          // googlePlayUrl="https://play.google.com/store/music/album/Young_Luke_Light_in_the_Dark?id=Bf2qrw2xzjdzs7eei33vgh6o6u4"
          youTubeUrl="https://www.youtube.com/watch?v=n5wihWWynhE&list=OLAK5uy_mHuYMFTbXAfvxT17kA9JLdYefPYs0D-90&ab_channel=YoungLuke-Topic"
          musicVideo={false}
          iTunesUrl="https://music.apple.com/us/album/light-in-the-dark/1533578182"
          appleMusicUrl="https://music.apple.com/us/album/light-in-the-dark/1533578182?uo=4&app=music&at=1001lry3&ct=dashboard"
          bandcampUrl="https://youngluke.bandcamp.com/album/light-in-the-dark"
          tidalUrl="https://listen.tidal.com/album/156687763"
          // soundCloudUrl="https://soundcloud.com/younglukemusic/sets/after-you"
        />
      </Container>
    </ContentPane>
  );
}
