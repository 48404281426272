import React from 'react';

import ContentPane from '../components/ContentPane';

export default function Shop() {
  return (
    <ContentPane
      className="p-5 d-flex flex-column justify-content-center align-items-center"
      bg="tempe-2"
    >
      <div className="d-flex align-self-center justify-content-center flex-column mx-auto bg-black-80 p-3 p-md-5 br-md bs-lg my-5 text-center shadow">
        <span role="img" aria-label="city" className="fs-xl">
          🌴 🌃
        </span>
        <h4 className="text-white fw-black">Coming Soon</h4>
        <div className="mx-auto text-white-50 font-italic">
          It'll all come in time...
        </div>
      </div>
    </ContentPane>
  );
}
