import React from 'react';

export default function Artwork({ youtubeUrl, artwork, alt }) {
  if (!youtubeUrl) {
    return <img src={artwork} alt={alt} className="img-fluid" />;
  }

  return (
    <a href={youtubeUrl} target="_blank" rel="noopener noreferrer">
      <img src={artwork} alt={alt} className="img-fluid" />
    </a>
  );
}
