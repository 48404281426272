import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { BetterArtwork, TimeToMyselfArtwork } from '../../assets/artwork';

export default function OtherReleases() {
  return (
    <ContentPane
      borderBottom={false}
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="palms"
    >
      <div>
        <h5 className="font-italic mb-0 text-center text-white pb-0 mt-5">
          More YL
        </h5>
        <hr className="hr hr-cloud d-inline-block mt-0" />
      </div>
      <Container>
        <Row>
          <Col xs="12" className="px-3">
            <Release
              artwork={BetterArtwork}
              title="Better"
              spotifyUrl="https://open.spotify.com/track/4urWAJ99dbmZfVclsuWfO1?si=VWJh1mRDR4q8B9vra5-iSw"
              youTubeUrl="https://youtu.be/8M_z59IpbpA"
              appleMusicUrl="https://music.apple.com/us/album/better-single/1435893129"
              tidalUrl="https://tidal.com/browse/track/94878809?play=true"
            />
            <Release
              artwork={TimeToMyselfArtwork}
              title="Time to Myself"
              spotifyUrl="https://open.spotify.com/track/5BgZbghddBxsQMS2B9n3Ll?si=mlun_a1CSPS91aNuqdSYdA"
              youTubeUrl="https://www.youtube.com/watch?v=fazdrhqzSU4"
              appleMusicUrl="https://music.apple.com/us/album/time-to-myself-single/1435879793"
              tidalUrl="https://tidal.com/browse/track/94875531?play=true"
            />
          </Col>
        </Row>
      </Container>
    </ContentPane>
  );
}
