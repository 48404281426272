import React, { Component } from 'react';
import {Container} from 'reactstrap';

import ContentPane from '../components/ContentPane';
import VideoPlayer from '../components/VideoPlayer';

class Video extends Component {
  render() {
    return (
      <ContentPane
        className="p-3 p-lg-5 d-flex flex-column justify-content-center align-items-center"
        bg="ghost-3">
          <Container className="px-0 px-lg-3 align-self-center">
            <h1 className="h4 text-center text-white text-uppercase font-weight-bold">Ghost</h1>
            <VideoPlayer
              url="https://player.vimeo.com/video/297378930?autoplay=1&color=ffffff&byline=0&portrait=0"
            />
          </Container>
      </ContentPane>
    );
  }
}

export default Video;
