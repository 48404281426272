import React from 'react';

export default function GoldButton({ className, children, ...rest }) {
  return (
    <button
      href=""
      style={{
        background: '#F3D19A',
        color: '#090911',
        fontSize: '18px',
        letterSpacing: '1px',
        fontFamily: 'Chronicle Display',
        textDecoration: 'none',
        borderRadius: '1000px',
        border: '0px',
        cursor: 'pointer',
        fontWeight: 'bold',
      }}
      className={`d-inline-block px-4 py-2 text-uppercase font-weight-black ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
}
