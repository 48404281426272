import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { SocialLinks, ContentPane } from '../components';
import PageHeader from '../components/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import palmLogo from '../assets/images/logos/yl/palm-white.svg';
import ylSerifLogo from '../assets/images/logos/yl/word-serif.svg';

import { litdFrontCover, litdBackCover } from '../assets/artwork';
import litdText from '../assets/images/litd/text.svg';
import GoldButton from '../components/GoldButton';

import styles from './Home.module.scss';

export default function Home() {
  const [flipped, setFlipped] = useState(false);
  const history = useHistory();

  return (
    <>
      <ContentPane
        borderBottom
        className="p-3 p-md-5 d-flex flex-column justify-content-center align-items-center"
        bg="litd"
      >
        <div className="d-flex flex-column align-items-center pt-5 pt-md-0 mt-5">
          <img
            className="litd-album-name px-3 px-md-0 mb-4 mb-md-5"
            src={litdText}
            alt="Light in the Dark"
          />
          <div className="text-center">
            <div
              className={`album-cover mb-4 mb-md-5 ${flipped ? 'flipped' : ''}`}
              onClick={() => setFlipped(!flipped)}
            >
              <img
                className="cover-front img-fluid mb-4 mb-md-5"
                style={{ maxWidth: 600, width: '100%' }}
                src={litdFrontCover}
                alt="Light in the Dark (Front)"
              />
              <img
                className="cover-back img-fluid mb-4 mb-md-5"
                style={{ maxWidth: 600, width: '100%' }}
                src={litdBackCover}
                alt="Light in the Dark (Back)"
              />
            </div>
          </div>

          <div className="">
            <GoldButton onClick={() => history.push('listen')}>
              <FontAwesomeIcon icon={['fas', 'headphones']} className="mr-2" />
              Listen Now
            </GoldButton>
            {/* <GoldButton className="mr-4">
                <FontAwesomeIcon
                  icon={['fas', 'record-vinyl']}
                  className="mr-2"
                />
                Buy
              </GoldButton> */}
          </div>

          {/* <div className="pb-4">
              <a href="https://younglukemusic.com">
                <img
                  src={ylLogo}
                  style={{ width: 37 }}
                  alt="Young Luke"
                  className="mr-5"
                />
              </a>
              <img
                src={attaliaLogo}
                style={{ width: 50 }}
                alt="Attalia Records, LLC"
              />
            </div>
            <div
              className="text-white font-italic pb-5"
              style={{ fontFamily: 'Chronicle Deck' }}
            >
              &copy; 2020 Attalia Records, LLC
            </div> */}
        </div>

        {/* <Row className="mt-4">
          <Col xs="12" sm="8" md="7" lg="6" xl="4" className="text-center mb-2">
            <img
              style={{ maxWidth: '350px', width: '100%' }}
              className="mb-4 mb-md-5"
              // className="px-3 px-md-0 mb-4 mb-md-5"
              src={LitdText}
              alt="Light in the Dark"
            />
            <NavLink to="/listen">
              <img
                src={LitdArtwork}
                className="img-fluid mb-3 rounded border border-carbon"
                style={{ border: '1px solid rgba(255,255,255,0.25)' }}
                alt="Light in the Dark"
              />
            </NavLink>
            <div className="d-flex align-items-center justify-content-center">
              <NavLink to="/listen" className="mb-0">
                <GoldButton
                  color="primary"
                  size="sm"
                  className="text-uppercase font-weight-regular ls-2"
                >
                  <FontAwesomeIcon icon="headphones-alt" className="mr-2" />{' '}
                  Listen Now
                </GoldButton>
              </NavLink>
            </div>
          </Col>
        </Row> */}
        <FontAwesomeIcon
          icon={['fal', 'angle-down']}
          size="2x"
          className="justify-self-end text-cloud mt-5 o-30"
        />
      </ContentPane>
      <ContentPane
        id="home"
        borderBottom={false}
        className="px-3 py-4 p-md-5 d-flex flex-column justify-content-center"
        bg="black"
      >
        <span className="mb-4 mt-5 mt-md-0 d-flex flex-column flex-md-row justify-content-center align-items-center">
          <img
            src={palmLogo}
            className={`${styles.palmLogo} mb-4 mb-md-0 mr-0 mr-md-3`}
            alt=""
          />
          <img src={ylSerifLogo} className={styles.ylSerifLogo} alt="" />
        </span>
        <Row>
          <Col
            xs="12"
            lg="6"
            className="d-flex flex-column align-items-center justify-content-start py-4"
          >
            <PageHeader className="mb-4" title="Connect" uppercase underline />
            <div className="d-flex">
              <SocialLinks className="align-self-center justify-self-center" />
            </div>
          </Col>
          <Col
            xs="12"
            lg="6"
            className="d-flex flex-column align-items-center justify-content-start py-4"
          >
            <PageHeader className="mb-3" title="Contact" uppercase underline />
            <div className="d-flex h-100 text-center">
              <p className="align-self-top justify-self-center">
                <a href="mailto:inquiries@younglukemusic.com" className="link">
                  <FontAwesomeIcon icon="envelope" className="mr-2" />
                  inquiries@younglukemusic.com
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </ContentPane>
    </>
  );
}
