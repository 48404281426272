import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Home, Listen, Photos, Shop } from './pages';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/listen">
        <Listen />
      </Route>
      <Route path="/photos" component={Photos} />
      <Route path="/shop" component={Shop} />
      {/* <Route path="/vault">
        <Releases />
      </Route> */}
    </Switch>
  );
}
