import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { SedonaArtwork } from '../../assets/artwork';

export default function Sedona() {
  return (
    <ContentPane
      borderBottom
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="sedona"
    >
      <Container className="py-4">
        <Release
          artwork={SedonaArtwork}
          title="Sedona"
          spotifyUrl="https://open.spotify.com/track/3R2JcKINE7RHZEuPgSnC8x?si=6f31c9ea0fef48cf"
          appleMusicUrl="https://music.apple.com/us/album/sedona-single/1586471207"
          tidalUrl="https://tidal.com/browse/album/198110224"
          soundCloudUrl="https://soundcloud.com/younglukemusic/sedona"
          iTunesUrl="https://music.apple.com/us/album/sedona/1586471207?i=1586471208"
          youTubeUrl="https://youtu.be/rbeBEDd3kJo"
        />
      </Container>
    </ContentPane>
  );
}
