import React from 'react';
import ReactPlayer from 'react-player';
import styles from './VideoPlayer.module.scss';

const VideoPlayer = ({url}) =>
  <div className={styles.playerWrapper}>
    <ReactPlayer
      url={url}
      className={styles.reactPlayer}
      width="100%"
      height="100%"
    />
  </div>

export default VideoPlayer;
