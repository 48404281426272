import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { TheWorstArtwork } from '../../assets/artwork';

export default function TheWorst() {
  return (
    <ContentPane
      borderBottom
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="the-worst"
    >
      <Container className="py-4">
        <Release
          artwork={TheWorstArtwork}
          title="The Worst"
          spotifyUrl="https://open.spotify.com/track/2lONVTUKOsysn9cvBieXkh?si=4c64b8c01b0b4323"
          appleMusicUrl="https://music.apple.com/us/album/the-worst-single/1584516784"
          tidalUrl="https://tidal.com/browse/track/196665725"
          soundCloudUrl="https://soundcloud.com/younglukemusic/the-worst"
          iTunesUrl="https://music.apple.com/us/album/the-worst/1584516784?i=1584516785"
          youTubeUrl="https://www.youtube.com/watch?v=-FYbRTq2PyQ"
        />
      </Container>
    </ContentPane>
  );
}
