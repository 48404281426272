import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import styles from './Button.module.scss';

const cx = classnames.bind(styles);

export default function Button({
  className,
  theme,
  size,
  href,
  width,
  children
}) {
  const buttonClasses = cx(
    className,
    'text-center align-items-center align-self-center justify-content-center align-content-center d-flex',
    styles.base,
    styles[theme],
    styles[size]
  );

  return (
    <a href={href} className={buttonClasses} style={{ width: `${width}` }}>
      {children}
    </a>
  );
}

Button.propTypes = {
  theme: PropTypes.oneOf(['primary', 'white-outline']),
  width: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  href: PropTypes.string
};

Button.defaultProps = {
  theme: 'primary',
  width: '',
  size: 'sm',
  href: ''
};
