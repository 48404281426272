import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { PassionateArtwork } from '../../assets/artwork';

export default function Passionate() {
  return (
    <ContentPane
      borderBottom
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="passionate"
    >
      <Container>
        <Release
          artwork={PassionateArtwork}
          title="Passionate"
          spotifyUrl="https://open.spotify.com/album/18aOOIKkKoMau6a4qm6SkI"
          soundCloudUrl="https://soundcloud.com/younglukemusic/passionate"
          youTubeUrl="https://youtu.be/sZzvGktmecc"
          appleMusicUrl="https://music.apple.com/us/album/passionate-single/1469148571?uo=4&app=music&at=1001lry3&ct=dashboard"
          iTunesUrl="https://music.apple.com/us/album/passionate-single/1469148571?app=itunes&ign-mpt=uo%3D4"
          tidalUrl="https://tidal.com/browse/track/111538442?play=true"
        />
      </Container>
    </ContentPane>
  );
}
