import React from 'react';
import { Button } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ActionButton({ icon, children, href }) {
  return (
    <Button
      theme="white-outline"
      className="ml-auto d-flex align-self-center"
      href={href}
    >
      <FontAwesomeIcon icon={icon} className="mr-2" />
      {children}
    </Button>
  );
}
