import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './FullScreenPhoto.module.scss';

export default function FullScreenPhoto({ name, url, onClose }) {
  return (
    <div
      className="d-flex justify-content-center align-items-center p-5 bg-black"
      style={{
        width: '100vw',
        height: '100vh',
        zIndex: '99999',
        position: 'fixed',
        overflow: 'hidden'
      }}
    >
      <img
        alt={name}
        src={url}
        style={{
          position: 'fixed',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          maxWidth: '100%',
          maxHeight: '100%',
          margin: 'auto',
          overflow: 'auto'
        }}
      />
      <button onClick={onClose}>
        <FontAwesomeIcon
          icon={['fal', 'times']}
          className={styles.closePhoto}
          size="2x"
        />
      </button>
    </div>
  );
}
