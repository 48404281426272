import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faSpotify,
  faSoundcloud,
  faYoutube,
  faApple,
  faInstagram,
  faBandcamp,
} from '@fortawesome/free-brands-svg-icons';
import {
  faLongArrowAltRight,
  faVideo,
  faHeadphonesAlt,
  faHome,
  faImages,
  faShoppingCart,
  faPlay,
  faEnvelope as fasEnvelope,
  faRecordVinyl,
  faHeadphones,
} from '@fortawesome/pro-solid-svg-icons';
import { faEnvelope as farEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { faAngleDown, faTimes } from '@fortawesome/pro-light-svg-icons';

library.add(
  // brands
  faSpotify,
  faSoundcloud,
  faYoutube,
  faApple,
  faInstagram,
  faBandcamp,

  // solid
  faLongArrowAltRight,
  faVideo,
  faHeadphonesAlt,
  faHome,
  faImages,
  faShoppingCart,
  fasEnvelope,
  faPlay,
  faRecordVinyl,
  faHeadphones,

  // regular
  farEnvelope,

  // light
  faAngleDown,
  faTimes
);
