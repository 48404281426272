import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { ReleaseTitle, ReleaseSubtitle } from '../Listen';
import { AfterYouArtwork } from '../../assets/artwork';

export default function AfterYou() {
  return (
    <ContentPane
      borderBottom
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="after-you"
    >
      <Container>
        <ReleaseTitle>After You - EP</ReleaseTitle>
        <ReleaseSubtitle>3 Songs</ReleaseSubtitle>
        <Release
          artwork={AfterYouArtwork}
          title="After You"
          showTitle={false}
          spotifyUrl="https://open.spotify.com/album/0bWtYKXvTKslbb2L9ZFxnI"
          spotifyEmbedUrl="https://open.spotify.com/embed/album/0bWtYKXvTKslbb2L9ZFxnI"
          spotifyEmbedHeight="178"
          youTubeUrl="https://www.youtube.com/watch?v=74DUdZ9lxZ8&list=OLAK5uy_lq-VoNNmZB_t4jeAyXzA4lkbkT6gvYMw0"
          musicVideo={false}
          appleMusicUrl="https://music.apple.com/us/album/after-you-single/1499190476?app=apple music"
          tidalUrl="https://tidal.com/browse/album/131511110?play=true"
          soundCloudUrl="https://soundcloud.com/younglukemusic/sets/after-you"
          // soundCloudEmbedUrl="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/992088748&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
        />
      </Container>
    </ContentPane>
  );
}
