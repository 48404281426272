import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { CloudsArtwork } from '../../assets/artwork';

export default function Clouds() {
  return (
    <ContentPane
      borderBottom
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="clouds"
    >
      <Container className="py-4">
        <Release
          artwork={CloudsArtwork}
          title="Clouds"
          spotifyUrl="https://open.spotify.com/album/5VrgcbjTrMWNXGzWumUiLV"
          appleMusicUrl="https://music.apple.com/us/album/clouds-single/1584581508?uo=4&app=music&at=1001lry3&ct=dashboard"
          tidalUrl="https://tidal.com/browse/album/196717138"
          soundCloudUrl="https://soundcloud.com/younglukemusic/clouds"
          iTunesUrl="https://music.apple.com/us/album/clouds-single/1584581508"
          youTubeUrl="https://www.youtube.com/watch?v=o4kbt6wFbRE"
        />
      </Container>
    </ContentPane>
  );
}
