import React from 'react';
import { Row, Col } from 'reactstrap';
import IFrame from 'react-iframe';
import { Artwork, ActionButton, BrandLogo } from '.';
import {
  StyledRelease,
  StyledReleaseContainer,
  StyledReleaseRow,
} from './StyledRelease';

import {
  Spotify,
  SoundCloud,
  AppleMusic,
  YouTube,
  Tidal,
  ITunes,
  Amazon,
  Bandcamp,
} from '../../assets/images/logos/brands';

export default function Release({
  title,
  showTitle = true,
  artwork,
  isMusicVideo = false,
  spotifyUrl,
  spotifyEmbedUrl,
  spotifyEmbedHeight,
  youTubeUrl,
  appleMusicUrl,
  tidalUrl,
  soundCloudUrl,
  soundCloudEmbedUrl,
  iTunesUrl,
  amazonUrl,
  bandcampUrl,
}) {
  return (
    <StyledRelease>
      <StyledReleaseContainer>
        <Row>
          <Col xs="12" xl="6" className="px-0">
            <Artwork youtubeUrl={youTubeUrl} artwork={artwork} alt={title} />
          </Col>
          <Col xs="12" xl="6" className="px-0">
            {showTitle && (
              <div className="p-3 bg-carbon bb-1-charcoal text-center d-flex d-none justify-content-between">
                <div className="d-flex mx-auto flex-column">
                  <h5 className="text-white mb-0 fw-black">{title}</h5>
                  <p className="d-none d-md-block text-pebble mb-0 font-italic fw-regular">
                    Young Luke
                  </p>
                </div>
              </div>
            )}
            {spotifyEmbedUrl && (
              <IFrame
                url={spotifyEmbedUrl}
                width="100%"
                height={spotifyEmbedHeight}
                frameborder="0"
                allowtransparency="true"
                allow="encrypted-media"
                id="spotifyEmbed"
              />
            )}
            {spotifyUrl && (
              <StyledReleaseRow>
                <BrandLogo
                  href={spotifyUrl}
                  src={Spotify}
                  alt={title}
                  height="32"
                />
                <ActionButton href={spotifyUrl} icon="headphones-alt">
                  Listen
                </ActionButton>
              </StyledReleaseRow>
            )}
            {youTubeUrl && (
              <StyledReleaseRow>
                <BrandLogo
                  href={youTubeUrl}
                  src={YouTube}
                  alt={title}
                  height="36"
                />
                <ActionButton
                  href={youTubeUrl}
                  icon={isMusicVideo ? 'play' : 'headphones-alt'}
                >
                  {isMusicVideo ? 'Watch' : 'Listen'}
                </ActionButton>
              </StyledReleaseRow>
            )}
            {appleMusicUrl && (
              <StyledReleaseRow>
                <BrandLogo
                  href={appleMusicUrl}
                  src={AppleMusic}
                  alt={title}
                  height="24"
                />
                <ActionButton href={appleMusicUrl} icon="headphones-alt">
                  Listen
                </ActionButton>
              </StyledReleaseRow>
            )}
            {tidalUrl && (
              <StyledReleaseRow>
                <BrandLogo
                  href={tidalUrl}
                  src={Tidal}
                  alt={title}
                  height="36"
                />
                <ActionButton href={tidalUrl} icon="headphones-alt">
                  Listen
                </ActionButton>
              </StyledReleaseRow>
            )}
            {soundCloudEmbedUrl && (
              <IFrame
                url={soundCloudEmbedUrl}
                width="100%"
                height="315px"
                id="soundcloudEmbed"
              />
            )}
            {soundCloudUrl && (
              <StyledReleaseRow>
                <BrandLogo
                  href={soundCloudUrl}
                  src={SoundCloud}
                  alt={title}
                  height="40"
                />
                <ActionButton href={soundCloudUrl} icon="headphones-alt">
                  Listen
                </ActionButton>
              </StyledReleaseRow>
            )}
            {iTunesUrl && (
              <StyledReleaseRow>
                <BrandLogo
                  href={iTunesUrl}
                  src={ITunes}
                  alt={title}
                  height="30"
                />
                <ActionButton href={iTunesUrl} icon="shopping-cart">
                  Buy
                </ActionButton>
              </StyledReleaseRow>
            )}
            {bandcampUrl && (
              <StyledReleaseRow>
                <BrandLogo
                  href={bandcampUrl}
                  src={Bandcamp}
                  alt={title}
                  height="20"
                />
                <ActionButton href={bandcampUrl} icon="headphones-alt">
                  Listen
                </ActionButton>
              </StyledReleaseRow>
            )}
            {amazonUrl && (
              <StyledReleaseRow>
                <BrandLogo
                  href={amazonUrl}
                  src={Amazon}
                  alt={title}
                  height="30"
                />
                <ActionButton href={amazonUrl} icon="shopping-cart">
                  Buy
                </ActionButton>
              </StyledReleaseRow>
            )}
          </Col>
        </Row>
      </StyledReleaseContainer>
    </StyledRelease>
  );
}
