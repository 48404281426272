import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import {
  AfterYou,
  InDueTime,
  Passionate,
  Ghost,
  OtherReleases,
  Pressure,
  Poolside,
  Ride,
  PalmTrees,
  ComeOver,
  Hotel,
  DarkPlaces,
  TheWorst,
  HighUp,
  Clouds,
  Sedona,
  WelcomeToTheParty,
} from './releases';
import ArizonaNights from './releases/ArizonaNights';
import LightInTheDark from './releases/LightInTheDark';

export const ReleaseTitle = ({ children }) => (
  <h1 className="h4 text-center text-white mt-3 mt-md-5 mb-0 pt-5 font-weight-bold">
    {children}
  </h1>
);

export const ReleaseSubtitle = ({ children }) => (
  <div className="text-white-75 text-center fs-sm">{children}</div>
);

export default function Listen() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <WelcomeToTheParty />
        <Sedona />
        <Clouds />
        <HighUp />
        <TheWorst />
        <DarkPlaces />
        <Hotel />
        <ArizonaNights />
        {/* <Hours /> */}
        <LightInTheDark />
        <ComeOver />
        {/* <Pressure /> */}
        {/* <AfterYou /> */}
        {/* <InDueTime /> */}
        <Passionate />
        <Ghost />
        <Ride />
        <Poolside />
        <PalmTrees />
        <OtherReleases />
      </Route>
      <Route path={`${match.path}/after-you`}>
        <AfterYou borderBottom={false} />
      </Route>
      <Route path={`${match.path}/pressure`}>
        <Pressure borderBottom={false} />
      </Route>
      <Route path={`${match.path}/in-due-time`}>
        <InDueTime borderBottom={false} />
      </Route>
      <Route path={`${match.path}/passionate`}>
        <Passionate borderBottom={false} />
      </Route>
      <Route path={`${match.path}/ghost`}>
        <Ghost borderBottom={false} />
      </Route>
      <Route path={`${match.path}/ride`}>
        <Ride borderBottom={false} />
      </Route>
      <Route path={`${match.path}/poolside`}>
        <Poolside borderBottom={false} />
      </Route>
      <Route path={`${match.path}/palm-trees`}>
        <PalmTrees borderBottom={false} />
      </Route>
      {/* <Route path={`${match.path}/better`}>
        <Better />
      </Route>
      <Route path={`${match.path}/time-to-myself`}>
        <TimeToMyself />
      </Route> */}
    </Switch>
  );
}
