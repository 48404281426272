import { Col } from 'reactstrap';
import styled from 'styled-components';

export const StyledReleaseRow = styled.div.attrs({
  className:
    'd-flex align-self-center justify-content-between py-3 px-3 bb-1-charcoal'
})`
  position: relative;
  text-decoration: none !important;
  background: ${props => props.theme.carbon};
`;

export const StyledRelease = styled.div.attrs({
  className: 'd-flex py-md-5 py-3'
})``;

export const StyledReleaseContainer = styled(Col).attrs({
  className:
    'mx-auto bg-carbon br-md bs-lg border border-width-1 border-color-charcoal',
  xs: '12',
  md: '8',
  lg: '6',
  xl: '12'
})`
  overflow: hidden;
`;
