import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { GhostArtwork } from '../../assets/artwork';

export default function Ghost() {
  return (
    <ContentPane
      borderBottom
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="ghost-5"
    >
      <Container>
        <Release
          artwork={GhostArtwork}
          title="Ghost"
          spotifyUrl="https://open.spotify.com/track/3UUcXQNBCNC4I0HL4IeS91"
          appleMusicUrl="https://itunes.apple.com/us/album/ghost-single/1444559740"
          tidalUrl="https://tidal.com/track/99539756"
          soundCloudUrl="https://soundcloud.com/younglukemusic/ghost"
          iTunesUrl="https://itunes.apple.com/us/album/ghost-single/1444559740"
          // youTubeUrl="https://www.youtube.com/watch?v=APOcHawDe8M"
        />
      </Container>
    </ContentPane>
  );
}
