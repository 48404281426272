import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { ComeOverArtwork } from '../../assets/artwork';

export default function ComeOver() {
  return (
    <ContentPane
      borderBottom
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="come-over"
    >
      <Container className="py-4">
        <Release
          artwork={ComeOverArtwork}
          title="Come Over"
          spotifyUrl="https://open.spotify.com/track/0JEi57TUWeFyV6Af3IDihh?si=lNb_KYDdR0anuiIC6QicwA"
          appleMusicUrl="https://music.apple.com/us/album/come-over-single/1521208084"
          tidalUrl="https://tidal.com/browse/album/146814142?play=true"
          soundCloudUrl="https://soundcloud.com/younglukemusic/come-over"
          iTunesUrl="https://music.apple.com/us/album/come-over/1521208084?i=1521208085"
          youTubeUrl="https://youtu.be/7M6rd3V-v7Y"
          isMusicVideo
        />
      </Container>
    </ContentPane>
  );
}
