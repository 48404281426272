import React from 'react';

const ContentPane = ({children, borderBottom, bg, className, id}) => {
  let borderClasses = borderBottom ? ' border-bottom border-width-2 border-color-charcoal' : '';

  return (
    <div id={id} className={`${className} ${borderClasses} bg-${bg}`} style={{minHeight: "100vh"}}>
      {children}
    </div>
  )
}

export default ContentPane;
