import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { RideArtwork } from '../../assets/artwork';

export default function Ride({ borderBottom = true }) {
  return (
    <ContentPane
      borderBottom={borderBottom}
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="ride"
    >
      <Container>
        <Release
          artwork={RideArtwork}
          title="Ride"
          spotifyUrl="https://open.spotify.com/track/3IUbofwz6EX5J7iauak535"
          youTubeUrl="https://youtu.be/7VWsjIPJ_nM"
          appleMusicUrl="https://itunes.apple.com/us/album/ride/1435902867?i=1435902868"
          tidalUrl="https://tidal.com/browse/track/94882266?play=true"
        />
      </Container>
    </ContentPane>
  );
}
