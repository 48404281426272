import React, { useState } from 'react';
import ContentPane from '../components/ContentPane';
import { CardColumns, Card, CardImg } from 'reactstrap';
import { FullScreenPhoto } from '../components';
import artistPhoto2017 from '../assets/images/artist_photos/2017.jpg';

import ghost1 from '../assets/images/releases/ghost/ghost-1.jpg';
import ghost2 from '../assets/images/releases/ghost/ghost-2.jpg';
import ghost3 from '../assets/images/releases/ghost/ghost-3.jpg';
import ghost4 from '../assets/images/releases/ghost/ghost-4.jpg';
import ghost5 from '../assets/images/releases/ghost/ghost-5.jpg';
import ghost6 from '../assets/images/releases/ghost/ghost-6.jpg';
import artistPhoto2018 from '../assets/images/artist_photos/2018.jpg';
import ghost7 from '../assets/images/releases/ghost/ghost-7.jpg';
import ghost8 from '../assets/images/releases/ghost/ghost-8.jpg';
import ghost9 from '../assets/images/releases/ghost/ghost-9.jpg';
import ghost10 from '../assets/images/releases/ghost/ghost-10.jpg';

export default function Photos() {
  const [photos] = useState([
    {
      name: 'Artist 1',
      url: artistPhoto2017,
    },
    {
      name: 'Ghost 1',
      url: ghost1,
    },
    {
      name: 'Ghost 2',
      url: ghost2,
    },
    {
      name: 'Ghost 3',
      url: ghost3,
    },
    {
      name: 'Ghost 4',
      url: ghost4,
    },
    {
      name: 'Ghost 5',
      url: ghost5,
    },
    {
      name: 'Ghost 6',
      url: ghost6,
    },
    {
      name: 'Artist 2',
      url: artistPhoto2018,
    },
    {
      name: 'Ghost 7',
      url: ghost7,
    },
    {
      name: 'Ghost 8',
      url: ghost8,
    },
    {
      name: 'Ghost 9',
      url: ghost9,
    },
    {
      name: 'Ghost 10',
      url: ghost10,
    },
  ]);
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const closePhoto = () => setCurrentPhoto(null);

  return (
    <>
      {currentPhoto && (
        <FullScreenPhoto
          name={currentPhoto.name}
          url={currentPhoto.url}
          onClose={closePhoto}
        />
      )}
      <ContentPane
        borderBottom={false}
        className="p-3 p-md-5 d-flex flex-column justify-content-center align-items-center"
        bg="black"
      >
        <CardColumns className="mt-5 pt-5">
          {photos.map((photo, i) => (
            <Card
              className="border-0 c-pointer"
              key={i}
              onClick={() => setCurrentPhoto(photo)}
            >
              <CardImg src={photo.url} alt={photo.name} />
            </Card>
          ))}
        </CardColumns>
      </ContentPane>
    </>
  );
}
