import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { InDueTimeArtwork } from '../../assets/artwork';

export default function InDueTime() {
  return (
    <ContentPane
      borderBottom
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="in-due-time"
    >
      <Container>
        <Release
          artwork={InDueTimeArtwork}
          title="In Due Time"
          spotifyUrl="https://open.spotify.com/album/7Cp65aZKEGjBYRWuRWeOJL"
          soundCloudUrl="https://soundcloud.com/younglukemusic/in-due-time"
          appleMusicUrl="https://music.apple.com/us/album/in-due-time-single/1489119839?app=music&ign-mpt=uo%3D4"
          iTunesUrl="https://music.apple.com/us/album/in-due-time-single/1489119839?app=itunes&ign-mpt=uo%3D4"
          youTubeUrl="https://youtu.be/Rn5xObsC_lE"
          tidalUrl="https://tidal.com/browse/track/123483051?play=true"
        />
      </Container>
    </ContentPane>
  );
}
