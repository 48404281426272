import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { PalmTreesArtwork } from '../../assets/artwork';

export default function PalmTrees({ borderBottom = true }) {
  return (
    <ContentPane
      borderBottom={borderBottom}
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="palm-trees"
    >
      <Container>
        <Release
          artwork={PalmTreesArtwork}
          title="Palm Trees"
          spotifyUrl="https://open.spotify.com/track/3NoycPdJBHfkaHqRjxQtnZ?si=1BwekmFpTdWZguBWu7qXBA"
          youTubeUrl="https://www.youtube.com/watch?v=l2Iq1oTBjoc"
          appleMusicUrl="https://music.apple.com/us/album/palm-trees-single/1448013001"
          tidalUrl="https://tidal.com/browse/album/101669173?play=true"
        />
      </Container>
    </ContentPane>
  );
}
