import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { WelcomeToThePartyArtwork } from '../../assets/artwork';

export default function WelcomeToTheParty() {
  return (
    <ContentPane
      borderBottom
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="welcome-to-the-party"
    >
      <Container className="py-4">
        <Release
          artwork={WelcomeToThePartyArtwork}
          title="Welcome to the Party"
          spotifyUrl="https://open.spotify.com/track/3BMKMQJTFQC28C3jlURxVJ?si=06df822716124f89"
          appleMusicUrl="https://music.apple.com/us/album/welcome-to-the-party/1587510547?i=1587510548"
          tidalUrl="https://tidal.com/browse/album/198928636"
          soundCloudUrl="https://soundcloud.com/younglukemusic/welcome-to-the-party"
          iTunesUrl="https://music.apple.com/us/album/welcome-to-the-party/1587510547?i=1587510548"
          youTubeUrl="https://youtu.be/UOHzGqKVIKg"
        />
      </Container>
    </ContentPane>
  );
}
