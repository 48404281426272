import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { PressureArtwork } from '../../assets/artwork';

export default function Pressure({ borderBottom }) {
  return (
    <ContentPane
      borderBottom={borderBottom}
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="pressure"
    >
      <Container className="py-4 py-md-0">
        <Release
          artwork={PressureArtwork}
          title="Pressure"
          spotifyUrl="https://open.spotify.com/track/6mGtOKgDhoWF9uoGi7WDgc?si=ohPZEdLvQrSws3bitaPfUw"
          soundCloudUrl="https://soundcloud.com/younglukemusic/pressure"
          appleMusicUrl="https://music.apple.com/us/album/pressure-single/1499315311"
          iTunesUrl="https://music.apple.com/us/album/pressure-single/1499315311?app=itunes"
          youTubeUrl="https://www.youtube.com/watch?v=R9FW0LcrKbg"
          tidalUrl="https://tidal.com/browse/track/131668117?play=true"
        />
      </Container>
    </ContentPane>
  );
}
