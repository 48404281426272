import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { HighUpArtwork } from '../../assets/artwork';

export default function HighUp() {
  return (
    <ContentPane
      borderBottom
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="high-up"
    >
      <Container className="py-4">
        <Release
          artwork={HighUpArtwork}
          title="High Up"
          spotifyUrl="https://open.spotify.com/track/0PEYO1dDaMRBnuISlb0bZn?si=08de8a3df5214cd3"
          appleMusicUrl="https://music.apple.com/us/album/high-up/1584520818?i=1584520819"
          tidalUrl="https://tidal.com/browse/album/196669461"
          soundCloudUrl="https://soundcloud.com/younglukemusic/high-up"
          iTunesUrl="https://music.apple.com/us/album/high-up-single/1584520818"
          youTubeUrl="https://youtu.be/AZFU8Iri0IQ"
        />
      </Container>
    </ContentPane>
  );
}
