import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { ArizonaNightsArtwork } from '../../assets/artwork';

export default function ArizonaNights() {
  return (
    <ContentPane
      borderBottom
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="arizona-nights"
    >
      <Container className="py-4">
        <Release
          artwork={ArizonaNightsArtwork}
          title="Arizona Nights"
          spotifyUrl="https://open.spotify.com/track/5dg4CZzEfwVglaHmn4NH3U?si=9ec3cdf0dd924463"
          appleMusicUrl="https://music.apple.com/us/album/arizona-nights/1568506250?i=1568506252"
          tidalUrl="https://tidal.com/browse/track/184873248"
          soundCloudUrl="https://soundcloud.com/younglukemusic/arizona-nights"
          iTunesUrl="https://music.apple.com/us/album/arizona-nights/1568506250?i=1568506252"
          youTubeUrl="https://youtu.be/yuaCw1UbHxQ"
        />
      </Container>
    </ContentPane>
  );
}
