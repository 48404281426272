import React from 'react';
import { Container } from 'reactstrap';
import { ContentPane, Release } from '../../components';
import { HotelArtwork } from '../../assets/artwork';

export default function Hotel() {
  return (
    <ContentPane
      borderBottom
      className="py-5 px-0 p-md-5 d-flex flex-column justify-content-center align-items-center"
      bg="hotel"
    >
      <Container className="py-4">
        <Release
          artwork={HotelArtwork}
          title="Hotel"
          spotifyUrl="https://open.spotify.com/track/2jLN4FFoKVLFK0j90fdOx0?si=e3495168eada428e"
          appleMusicUrl="https://music.apple.com/us/album/hotel/1583536173?i=1583536174"
          tidalUrl="https://tidal.com/browse/album/195817014"
          soundCloudUrl="https://soundcloud.com/younglukemusic/hotel"
          iTunesUrl="https://music.apple.com/us/album/hotel/1583536173?i=1583536174"
          youTubeUrl="https://www.youtube.com/watch?v=lH6wHR7nR1Y"
        />
      </Container>
    </ContentPane>
  );
}
